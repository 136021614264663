.ftco-section {
    padding: 5em 0;
}

.row-2 {
    margin-right: -15px;
    margin-left: -12px;
}

.fgs{
    font-family: "Montserrat", sans-serif;
}

.butto{
    margin-bottom: 30px !important;
    margin-top: 20px !important;
}

.nt-mber{
    margin-top: 10px !important
}

.backg{
    background: #f8f9fd !important;
}



.form-group .label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
    font-weight: 700;
}
.form-group {
    margin-bottom: 20px;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}
.img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
}

.login-wrap {
    padding: 30px;
    width: 100%;
    position: relative;
    background: #fff;
}

@media (max-width: 991.98px){
.img, .login-wrap {
    width: 100%;
}
}
@media (max-width: 767.98px) {
    .wrap .img {
      height: 250px; 
    } 
    .d-md-flex{
        display: block !important;
    }
}
      
@media (min-width: 768px){
    .p-md-5 {
        padding: 3rem !important;
    }
    .container-login {
        width: 750px;
    }
}
@media (min-width: 1024px) {
    .img, .login-wrap {
        width: 50%;
    }
}

@media (max-width: 350px){
    .container-login{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .login-wrap{
        padding: 10px !important;
    }
}

.loader {
    text-align: center;
    background-color: #0d6efd;
    width: 100%;
    height: 40px;
    border-color: #2e50fb;
	border: none;
	cursor: pointer;
	color: #fff;
	font-weight: 700;
	font-size: 15px;
    justify-content: center;
    border-radius: 0.25rem;
}

.center-btn{
    margin: auto;
    width: 40px;
    height: 40px;
    padding: 5px;
}
.center-delete{
    margin: auto;
}
.d-md-flex {
    display: flex ;
}
.justify-content-center {
    justify-content: center !important;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

.sbmt{
    width: 100%;
}

.container-login {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}



.btn-2 {
    text-align: center;
    background-color: #0d6efd;
    width: 100%;
    height: 40px;
    border-color: #0d6efd;
	border: none;
	cursor: pointer;
	color: #fff;
	font-size: 15px;
    justify-content: center;
    border-radius: 0.25rem;
    font-family: "Montserrat", sans-serif;
}

.btn-2:hover{
    background-color: #0b5ed7;
    border-color: #0a58ca;
}
.btn-2:active{
    background-color: #0b5ed7;
    border-color: #0a58ca;
}