div#drip-ef-221589843 {
    text-align: center;
    background-color: white;
    padding-top: 50px;
}

#drip-ef-221589843 {
    font: 0.875rem/1.5rem sans-serif;
    width: 100%;
}

#drip-ef-221589843 h3 {
    letter-spacing: 3px;
    font-family: "Montserrat"!important;
    color: black;
    line-height: 1.3;
}



#drip-ef-221589843 > div {
    margin-bottom: 0.75rem;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid black !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid black !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1 !important;
}

.carousel-slide-caption > h3::before {
    content: "“";
    padding: 0 10px 0 0;
    color: #008080 !important;
}
.carousel-slide-caption > h3::after {
    content: "”";
    padding: 0 0 0 10px;
    color: #008080 !important;
}

.carousel-slide-caption {
    width: 70% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}


div.testimonial-source::before {
    
    display: block;
    padding-top: 50px;
    padding-bottom: 20px;
}

.carousel-slide-caption > .testimonial-source > h3 {
    color: #008080 !important;
    font-size: 18px;
}

.reviews-back{
    background-image: url('../assets/african-pattern.png') !important;
    background-repeat: no-repeat;
    background-size: 150px;
    background-position: 0 20px;
}