.carousel-slide-caption-gallery {
    width: 95% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.home-slider {
    background-size: cover !important;
      background-position: center center !important;
  }

.gallery-images{
    width: 95% !important;
}
.gallery-border-div{
    width: 95% !important;
}

@media (min-width:668px) and (max-width:767px){
    .gallery-images{
        max-width: 700px !important;
    }
}

@media (min-width:767px){
    .carousel-slide-caption-gallery {
        width: 80% !important;
    }
    
    .gallery-images{
        width: 80% !important;
    }
    .gallery-border-div{
        width: 80% !important;
    }
}


@media (min-width:400px){
    .gallery-back{
        background-repeat: no-repeat;
        background-size: 350px 40px;
        background-position: center 20px;
    }

}
@media (max-width:400px){
    .gallery-back{
        background-repeat: no-repeat;
        background-size: 100vw 20px;
        background-position: 0px 20px;
    }

}



@media (max-width: 875px){
	.grid-gallery{
		display: none !important;
	}
}

.grid-gallery {
	
	margin: 0 auto;
	
	display: flex;
	flex-wrap: wrap;
	width: 870px;

}
.grid-gallery-item { margin: 5px; max-width: 280px !important;  }

.grid-gallery-item img{
	max-width: 280px !important;
	margin-bottom: 10px;
}

@media (max-width: 767px) {
    .parent{
        margin-top: 50px;
    }
}

.slider {
    margin:0 15px;
    overflow:"hidden";
    padding:2rem 0;
  }
  
  .slider img {
    width: 100%;
    border-radius:10px;
  }
  
  .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  }
  
  .custom-dot-list-style button{
  border: none;
  background: rgb(255, 68, 68);
  }
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: rgb(255, 68, 68) !important;
  }

  .glide__slides-1 {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
}


.glide__slide-1 {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    cursor: grab;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

.home-para-slider::before{
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    opacity: 1 !important;
    transition: opacity 2s ease-in-out;
    background-position: center center !important;
    background-size: cover !important;
}
.home-parallax-1{
    background-position: center center !important;
    background-size: cover !important;
}
.home-parallax-1::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/1.jpg) !important;
}
.home-parallax-2::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/2.jpg) !important;
}
.home-parallax-3::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/3.jpg) !important;
}
.home-parallax-4::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/4.jpg) !important;
}
.home-parallax-5::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/5.jpg) !important;
}
.home-parallax-6::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/6.jpg) !important;
}
.home-parallax-7::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/7.jpg) !important;
}
.home-parallax-8::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/8.jpg) !important;
}
.home-parallax-9::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/9.jpg) !important;
}
.home-parallax-10::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/10.jpg) !important;
}
.home-parallax-11::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/11.jpg) !important;
}
.home-parallax-12::before{
    background-image: url(https://res.cloudinary.com/dwxzlruyd/image/upload/v1714774477/kilimaj-hiking/Home-Treks-Gallery-Slider/12.jpg) !important;
}

.home-para-slider:hover::before {
    opacity: 0 !important;
}