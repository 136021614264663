#consultation-form{
    font-family: 'Montserrat';
    text-align: center;
    display: inline-block;
    width: 100%;
    background-color: #F3F1F0;
        background-image: url('../assets/w-bg.png') !important;
        background-repeat: repeat;
        background-size: contain;
}

.dont-display-label{
    display: none !important;
}

.hs-custom-style .hs-input, .hs-custom-style fieldset {
    max-width: 100%;
}

.hs-custom-style fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    /* max-width: 500px; */
}

@media (max-width: 300px){
    #h3-title{
        font-size: 16px !important;
    }
    #h3-subtitle{
        font-size: 14px !important;
    }
}

@media (min-width: 300px) and (max-width: 440px){
    #h3-title{
        font-size: 21px !important;
    }
    #h3-subtitle{
        font-size: 18px !important;
    }
}
@media (min-width: 600px) and (max-width: 860px){
    .hs-custom-style {
        padding-right: 16px !important;
        padding-left: 16px !important;
    }
}


@media (max-width: 600px){
    .hs-custom-style fieldset.form-columns-3 .hs-form-field {
        float: none !important;
        width: 100% !important;
    }
    .hs-custom-style fieldset.form-columns-3 {
        padding-top: 20px !important;
        padding-right: 16px !important;
        padding-left: 16px !important;
    }
    .hs-input {
        width: 100% !important;
    }
}

.hs-custom-style fieldset.form-columns-3 .hs-form-field {
    float: left;
    width: 33.3%;
}

.field-2 {
    margin-bottom: 22px;
}

.hs-custom-style fieldset.form-columns-3 .hs-form-field label {
    color: #33475b;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    display: block;
    float: none;
    width: auto;
    font-weight: 500;
    line-height: 20px;
    padding-top: 0;
    margin-bottom: 4px;
    text-align: left;
}

.hs-custom-style fieldset.form-columns-3 .hs-form-field .input {
    margin-right: 8px;
}

.hs-input:focus-visible {
    outline: none !important;
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 2px solid black !important;
}
.whatsapp-input input:focus-visible {
    outline: none !important;
    border-bottom: 2px solid black !important;
}


.hs-custom-style fieldset.form-columns-3 .hs-whatsapp input {
    display: inline-block;
    width: 70%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    box-sizing: border-box;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #cad5e2;
    padding: 0 15px;
    min-height: 27px;
    background: none !important;
}

.hs-input {
    display: inline-block;
    width: 90%;
    height: 40px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    box-sizing: border-box;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #cad5e2;
    padding: 0 15px;
    min-height: 27px;
}

.input .hs-input {
    background: none;
}

.hs-form-field input[type=text], .hs-form-field input[type=email] {
    -webkit-appearance: none;
}

.hs-custom-style fieldset>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]) {
    width: 100%;
    max-width: 100%;
}

.actions {
    text-align: center;
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 17px 0px;
}

.hs-button {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    position: relative;
    text-align: center;
    transition: all .15s linear;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    padding: 12px 24px;
    background: #000000;
    border-color: #048484;
    color: #ffffff;
    font-size: 16px;
    line-height: 16px;
}

.hs-button-2 {
    width: 206px !important;
    border-width: 1px;
    cursor: no-drop;
    border-radius: 3px;
    border-style: solid;
    padding: 0px 24px;
    background: #000000;
    border-color: #048484;
}




#consultation-form .leafygreen-ui-5klvzg {
    font-family: 'Montserrat' !important;
}

#consultation-form .css-1tmhw5y {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    margin-bottom: 50px;
}