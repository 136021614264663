/*

===================================================================
GLIDE SLIDER - GLIDE.CSS
===================================================================

*/

.glide {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.glide * {
    box-sizing: inherit;
}

.glide__track {
    overflow: hidden;
}

.glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
}

.glide__slides--dragging {
    user-select: none;
}

.glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    cursor: grab;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

.glide__slide a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.glide__arrows {
    -webkit-touch-callout: none;
    user-select: none;
}

.glide__bullets {
    -webkit-touch-callout: none;
    user-select: none;
}

.glide--rtl {
    direction: rtl;
}

.glide__arrow:before {
    font-family: "Icons";
    content: "\68";
    position: absolute;
    font-size: 25px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    left: 0;
    top: 0;
    transition: all .5s;
}

.glide__arrow--right:before {
    content: "\67";
}

.glide__arrow {
    border: none;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    overflow: hidden;
    background: none;
    padding: 0;
    left: 15px;
    outline: none;
    cursor: pointer;
}

.glide__arrow--right {
    right: 15px;
    left: auto;
}

.glide__arrow:hover:before {
    margin-left: -10px;
}

.glide__arrow--right:hover:before {
    margin-left: 10px;
}

.glide__bullets {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 20px;
}

.glide__bullets > button {
    border: none;
    border-radius: 50%;
    margin: 0 5px;
    width: 10px;
    padding: 0;
    height: 10px;
    cursor: pointer;
    outline: none;
    opacity: .8;
    transition: all .5s;
}

.glide__bullets > button:hover, .glide__bullets > button.glide__bullet--active {
    opacity: 1;
}

.controls-out {
    z-index: 5;
    margin-bottom: 30px;
}

.controls-out:not([data-options*=nav]) {
    margin-bottom: 0;
}

.controls-out .glide__arrow {
    left: -40px;
}

.controls-out .glide__arrow--right {
    right: -40px;
    left: auto;
}

.controls-out .glide__bullets {
    bottom: -30px;
}

@media (max-width: 575.98px) {
    .controls-out .glide__arrow {
        left: -30px;
    }

    .controls-out .glide__arrow--right {
        right: -30px;
        left: auto;
    }
}
